<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    profilePage() {
      return () => import(`@/components/Themes/${this.profile?.theme?.name ?? 'default'}/Profile`);
    }
  },
  methods: {},
};
</script>
<template>
  <component :is="profilePage" v-if="profile && profilePage"></component>
</template>